import React from 'react'

type Props = {
  fill?: string
  width?: string
}

const Twiiter: React.FC<Props> = ({ fill = '#1da1f2', width }) => (
  <svg x="0px" y="0px" viewBox="0 0 24 24" fill={fill} width={width}>
    <path d="M7.8,21.1c8.5,0,13.1-7,13.1-13.1c0-0.2,0-0.4,0-0.6C21.8,6.8,22.6,6,23.2,5c-0.8,0.4-1.7,0.6-2.7,0.7c1-0.6,1.7-1.5,2-2.6 c-0.9,0.5-1.9,0.9-2.9,1.1c-0.8-0.9-2-1.5-3.4-1.5c-2.5,0-4.6,2.1-4.6,4.6c0,0.4,0,0.7,0.1,1.1C8,8.3,4.6,6.5,2.3,3.7 C1.9,4.4,1.7,5.2,1.7,6c0,1.6,0.8,3,2.1,3.8C3,9.8,2.3,9.6,1.7,9.3c0,0,0,0,0,0.1c0,2.2,1.6,4.1,3.7,4.5C5,14,4.6,14,4.2,14 c-0.3,0-0.6,0-0.9-0.1c0.6,1.8,2.3,3.2,4.3,3.2c-1.6,1.2-3.6,2-5.7,2c-0.4,0-0.7,0-1.1-0.1C2.8,20.4,5.2,21.1,7.8,21.1"></path>
  </svg>
)

export default React.memo(Twiiter)
