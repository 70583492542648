import React from 'react'

type Props = {
  fill?: string
  width?: string
}

const Hatena: React.FC<Props> = ({ fill = '#008fed', width }) => (
  <svg x="0px" y="0px" viewBox="0 0 24 24" fill={fill} width={width}>
    <path d="M14.2,12.5c-0.7-0.7-1.6-1.2-2.8-1.2c1-0.3,1.7-0.7,2.3-1.2c0.5-0.5,0.7-1.4,0.7-2.3c0-0.7-0.2-1.4-0.5-1.9 c-0.3-0.5-0.9-1-1.4-1.4c-0.5-0.3-1.2-0.5-1.9-0.7C9.8,3.7,8.6,3.7,6.7,3.7H2v16.7h4.9c1.9,0,3.3,0,4.2-0.2 c0.9-0.2,1.6-0.3,2.1-0.7c0.7-0.3,1.2-0.9,1.6-1.6c0.3-0.7,0.5-1.4,0.5-2.4C15.2,14.3,14.9,13.4,14.2,12.5z M6.3,7.3h1 c1.2,0,1.9,0.2,2.3,0.3C10,7.8,10.2,8.4,10.2,9c0,0.7-0.2,1-0.7,1.2c-0.3,0.2-1.2,0.3-2.3,0.3H6.3V7.3L6.3,7.3z M10.2,16.9 c-0.5,0.3-1.2,0.3-2.3,0.3H6.3v-3.6h1.7c1,0,1.9,0.2,2.3,0.3s0.7,0.7,0.7,1.4C10.9,16.3,10.7,16.7,10.2,16.9L10.2,16.9z"></path>
    <path d="M19.9,16.2c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1c1.2,0,2.1-0.9,2.1-2.1l0,0C22.2,17,21.1,16.2,19.9,16.2z"></path>
    <rect x="18.2" y="3.7" width="3.6" height="11.1"></rect>
  </svg>
)

export default React.memo(Hatena)
