import React from 'react'

type Props = {
  fill?: string
  width?: string
}

const Feedly: React.FC<Props> = ({ fill = '#2bb24c', width }) => (
  <svg x="0px" y="0px" viewBox="0 0 24 24" fill={fill} width={width}>
    <path d="M9.7,21.7h4.8c0.8,0,1.5-0.3,2-0.8l5.7-5.7c1.1-1.1,1.1-2.9,0-4L14,3.1c-1.1-1.1-2.9-1.1-4,0l-8.1,8.1c-1.1,1.1-1.1,2.9,0,4 l5.7,5.7C8.3,21.4,9.1,21.7,9.7,21.7z M8.5,16.2L8,15.7c-0.2-0.2-0.2-0.5,0-0.6l3.4-3.4c0.2-0.2,0.5-0.2,0.6,0l1.1,1.1 c0.2,0.2,0.2,0.5,0,0.6l-2.8,2.8c-0.2,0.2-0.2,0.2-0.3,0.2H8.9C8.8,16.3,8.6,16.3,8.5,16.2z M13.5,18.5l-0.5,0.5 c-0.2,0.2-0.2,0.2-0.3,0.2h-1.1c-0.2,0-0.3,0-0.3-0.2l-0.5-0.5c-0.2-0.2-0.2-0.5,0-0.6l1.1-1.1c0.2-0.2,0.5-0.2,0.6,0l1.1,1.1 C13.7,18,13.7,18.3,13.5,18.5z M5.4,12.3l6.1-6.1C11.7,6,12,6,12.2,6.2l1.1,1.1c0.2,0.2,0.2,0.5,0,0.6l-5.5,5.5 c-0.2,0.2-0.2,0.2-0.3,0.2H6.2c-0.2,0-0.3,0-0.3-0.2l-0.5-0.5C5.2,12.9,5.2,12.6,5.4,12.3z"></path>
  </svg>
)

export default React.memo(Feedly)
